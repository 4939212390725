import React, { createContext, useContext, useState, useEffect } from  'react'
import axios from 'axios'
import { HistoricalChart, CoinList, TrendingCoins, SingleCoin } from '../config/api'

const StateContext = createContext()

const initialState = {
    chat: false,
    cart: false,
    userProfile: false,
    notification: false,
}


export const ContextProvider = ({children}) => {

    const [activeMenu, setActiveMenu] = useState(false)
    const [auth, setAuth] = useState(false)
    const [loggedIn, setLoggedIn] = useState(false)
    const [coin, setCoin] = useState([])
    const [graphData, setGraphData] = useState([])
    const [coinData, setCoinData] = useState([])
    const [direction, setDirection] = useState('long')

    const [newHodler, setNewHodler] = useState(false)
    const [newSavings, setNewSavings] = useState(false)
    const [verifyMessage, setVerifyMessage] = useState(false)
    const [verifyStatus, setVerifyStatus] = useState(false)
    const [userData, setUserData] = useState({
        uuid: '',
        email: '',
        balance: [],
        withdrawals: [],
        authyQ: false
    })

    const title = {
        0: 'Personal Information',
        1: 'Identity & Confirmation'
    }
    const [page, setPage] = useState(0)
    const [fullUserData, setFullUserData] = useState({
        bfirstName: '',
        blastName: '',
        bdob: '',
        baddress: '',
        bzipcode: '',
        bcountry: '',
        sbeneficial: false,
        optIn: false,
        smoi: '',
        phone: ''
    })

    const handleFormChange = e => {
        const type = e.target.type

        const name = e.target.name

        const value = type === "checkbox"
            ? e.target.checked
            : e.target.value

        setFullUserData(prevData => ({
            ...prevData,
            [name]: value
        }))
    }

    const { phone, optIn, ...requiredInputs } = fullUserData
    const canSubmit = [...Object.values(requiredInputs)].every(Boolean) && page === Object.keys(title).length -1 

    const canNextPage1 = Object.keys(fullUserData)
        .filter(key => key.startsWith('b'))
        .map(key => fullUserData[key])
        .every(Boolean)


    const disablePrev = page === 0

    const disableNext =
        (page === Object.keys(title).length - 1)
        || (page === 0 && !canNextPage1)

    const prevHide = page === 0 && "remove-button"

    const nextHide = page === Object.keys(title).length - 1 && "remove-button"

    const submitHide = page !== Object.keys(title).length - 1 && "remove-button"

    const [loanData, setLoanData] = useState([])
    const [savingsData, setSavingsData] = useState([])
    const [hodlerData, setHodlerData] = useState([])

    const [isClicked, setIsClicked] = useState(initialState)
    const [screenSize, setScreenSize] = useState(undefined)
    const [coinsList, setCoinsList] = useState([])
    const [trending, setTrending] = useState([])
    const [coinState, setCoinState] = useState({
        id: 'ethereum',
        symbol: 'eth'
    })
    const [days, setDays] = useState('365')
    const [depositData, setDepositData] = useState([])
    const [withdrawalData, setWithdrawalData] = useState([])
    const [allBalance, setAllBalance] = useState([])
    const [allWithdrawal, setAllWithdrawal] = useState([])
    const [savingsInvest, setSavingsInvest] = useState([])
    const [makeSavings, setMakeSavings] = useState([])
    const [balanceArray, setBalanceArray] = useState([])
    const [pnl, setPnl] = useState(0.00)
    const [savingsBalance, setSavingsBalance] = useState(0.00)

    const handleClick = (clicked) => {
        setIsClicked({...initialState, [clicked]: true})
    }

    useEffect(() => {
        const storedAuth = localStorage.getItem('auth')
        const storedUserData = JSON.parse(localStorage.getItem('userData'))

        if (storedAuth === 'true') {
            setAuth(true)
            setLoggedIn(true)
            if (storedUserData) {
                setUserData(storedUserData)
            }
        }
    }, [])

    useEffect(() => {
        axios.get('https://api.optionmates.com/dashboard', {
            withCredentials: true,
          })
        .then(res => {
        if(res.data.Status === "Success") {
            setAuth(true)
            setLoggedIn(true)
        } else {
            setAuth(false)
        }
        })
    }, [])

    useEffect(()=> {
        axios.post('https://api.optionmates.com/depositdata', {uuid: userData.uuid, method: coinState.id})
        .then(res => {
          if(res.data.Status === "Success") {
            setDepositData(res.data.data)
            
          } else {
              setDepositData([])
          }
        })
        .catch(err => console.log(err))
    },[coinState])


    useEffect(()=> {
        axios.post('https://api.optionmates.com/withdrawaldata', {uuid: userData.uuid, method: coinState.id})
        .then(res => {
          if(res.data.Status === "Success") {
            setWithdrawalData(res.data.data)
            
          } else {
              setWithdrawalData([])
          }
        })
        .catch(err => console.log(err))
    },[coinState])

    useEffect(() => {
        if (userData?.uuid) {  // Check that userData and uuid exist before making the request
            axios.post('https://api.optionmates.com/alldeposits', { uuid: userData.uuid })
            .then(res => {
                setUserData(prevUserData => ({
                    ...prevUserData,
                    balance: res.data
                }))
                setAllBalance(res.data)
            })
            .catch(err => console.log(err))
        }
    }, [userData.uuid])

    useEffect(() => {
        axios.post('https://api.optionmates.com/allwithdrawals', {uuid: userData.uuid})
        .then(res => {
            setUserData({
                ...userData,
                withdrawals: res.data
            })
            setAllWithdrawal(res.data)
        })
        .catch(err => console.log(err))
    }, [userData.uuid])


    useEffect(() => {
        axios.post('https://api.optionmates.com/loandata', {uuid: userData.uuid})
        .then(res => {
            setLoanData(res.data)
        })
        .catch(err => console.log(err))
    }, [userData.uuid])


    useEffect(() => {
        axios.post('https://api.optionmates.com/savingsdata', {uuid: userData.uuid})
        .then(res => {
            setSavingsData(res.data)
        })
        .catch(err => console.log(err))
    }, [userData.uuid])

    useEffect(() => {
        axios.post('https://api.optionmates.com/currentsavingsdata', {uuid: userData.uuid, status: 'open'})
        .then(res => {
            setSavingsInvest(res.data)
        })
        .catch(err => console.log(err))
    }, [userData.uuid])

    useEffect(() => {
        axios.post('https://api.optionmates.com/hodlerdata', {uuid: userData.uuid})
        .then(res => {
            setHodlerData(res.data)
        })
        .catch(err => console.log(err))
    }, [userData.uuid])

    
    const getBeforeCurrentSavings = () => {
        if(savingsInvest.length > 0) {
          const currentSavings = savingsInvest.map(item => {
            return item.amountusd
          })
          return currentSavings
        } else {
          return ['0.00']
        }
    }

    useEffect(() => {
        setMakeSavings(getBeforeCurrentSavings())
    }, [])

    const makeFirstSavings = () => {
        if (makeSavings.length > 0) {
            return makeSavings.reduce((acc, curr)=> Number(acc) + Number(curr))
        } else {
            return 0.00
        }
    }

    useEffect(() => {
        makeFirstSavings()
    }, [])

    // useEffect(() => {
    //     if (savingsData && savingsData.length > 0) {
    //         const totalSavings = makeSavings();
    //         setSavingsBalance(totalSavings);       
    //     } else {
    //         setSavingsBalance(0); // Reset PnL if hodlerData is empty or undefined
    //     }
    // }, []); 
    
    

    const fetchCoin = async () => {
        
        const {data} = await axios.get(HistoricalChart(coinState.id, days, 'USD'))

        setCoin(data)
        
        
        const graphData = data.prices.map(price => {
            const [timestamp, p] = price
            const date = new Date(timestamp).toLocaleDateString('en-us')
            return {
                date: date,
                price: p,
                }
        })
        setGraphData(graphData)
    }


    const fetchTrendingCoins = async () => {
        const { data } = await axios.get(TrendingCoins('USD'))
    
        setTrending(data)
        
    }

    const fetchCoinData = async () => {
        const { data } = await axios.get(SingleCoin(coinState.id))

        setCoinData(data)
        
    }

    const fetchCoinsList = async () => {
        const { data } = await axios.get(CoinList('USD'))
    
        setCoinsList(data)
        
    }

    // const getPnl = async (uuid) => {
    //     try {
    //         // Make a GET request to the server to fetch hodler data
    //         const response = await axios.get(`/api/hodler/${uuid}`);
    //         console.log(response)
    
    //         const hodlerData = response.data;
    
    //         if (hodlerData && hodlerData.length > 0) {
    //             // Map the data to get the profit_loss values
    //             let profitLossArray = hodlerData.map(each => each.profit_loss || 0);
    
    //             // Use reduce to sum up the profit_loss values
    //             let totalPnl = profitLossArray.reduce((acc, curr) => acc + curr, 0);
    
    //             return totalPnl.toFixed(3); // Return the calculated PnL as a string with 3 decimals
    //         } else {
    //             return (0).toFixed(3); // No trades found, return 0.000
    //         }
    //     } catch (error) {
    //         console.error('Error fetching PnL:', error);
    //         return (0).toFixed(3); // Handle errors gracefully, return 0.000
    //     }
    // };

    // useEffect(() => {
    //     const fetchPnl = async () => {
    //       if (userData.uuid) {
    //         const totalPnl = await getPnl(userData.uuid);
    //         setPnl(totalPnl);
    //       }
    //     };
    
    //     fetchPnl();
    // }, [userData.uuid]);

    const getPnl = ()=> {
        let processArray = hodlerData.map(each => {
            return Number(each.profit_loss)
        })

        if (processArray.length > 0) {
            const total = processArray.reduce((acc, curr)=> Number(acc) + Number(curr))
            return total
        } else {
            return 0.00
        }
    }

    useEffect(() => {
        const totalPnl = getPnl();
        
        setPnl(totalPnl);
        // if (hodlerData && hodlerData.length > 0) {
        //     const totalPnl = getPnl(); // Call getPnl
        //     console.log('Setting PnL:', totalPnl);
        //     setPnl(totalPnl);         // Update state with the result
        // } else {
        //     setPnl(0); // Reset PnL if hodlerData is empty or undefined
        // }
    }, [userData.uuid, hodlerData]); 

    // const getBalance = (id) => {
    //     let cid = id.charAt(0).toUpperCase() + id.slice(1)
    
    //     let balance = allBalance.filter(item => item.method === cid)
    //     let withBalance = allWithdrawal.filter(item => item.method === cid)
        
    
    //     let arrayBalance = balance.map(each => {
    //       return each.amount
    //     })
         
    
    //     let withdrawBalance = withBalance.map(each => {
    //       return each.amount
    //     })
    
    //     if (arrayBalance.length && withdrawBalance.length > 0) {
    //       let a =  arrayBalance.reduce((acc, curr)=> Number(acc) + Number(curr))
    //       let b =  withdrawBalance.reduce((acc, curr)=> Number(acc) + Number(curr))
    //       let c = (a - b).toFixed(3)
    //       return c + pnl
    //     } else if (arrayBalance.length > 0 && withdrawBalance.length === 0) {
    //       let d = arrayBalance.reduce((acc, curr)=> Number(acc) + Number(curr))
    //       return d + pnl
    //     } else {
    //       return 0.00 + pnl
    //     }
    // }

    const getBalance = (id) => {
        let cid = id.charAt(0).toUpperCase() + id.slice(1);
    
        let balance = allBalance.filter(item => item.method === cid);
        let withBalance = allWithdrawal.filter(item => item.method === cid);
    
        let arrayBalance = balance.map(each => {
            return each.amount;
        });
    
        let withdrawBalance = withBalance.map(each => {
            return each.amount;
        });
    
        // Calculate total balance and apply PnL only for USDT
        if (arrayBalance.length && withdrawBalance.length > 0) {
            let a = arrayBalance.reduce((acc, curr) => Number(acc) + Number(curr));
            let b = withdrawBalance.reduce((acc, curr) => Number(acc) + Number(curr));
            let c = (a - b).toFixed(3);
            return cid === "Tether" ? (Number(c) + Number(pnl)).toFixed(3) : c;
        } else if (arrayBalance.length > 0 && withdrawBalance.length === 0) {
            let d = arrayBalance.reduce((acc, curr) => Number(acc) + Number(curr));
            return cid === "Tether" ? (Number(d) + Number(pnl)).toFixed(3) : d;
        } else {
            return cid === "Tether" ? (0.00 + Number(pnl)).toFixed(3) : 0.00;
        }
    };
    

    const getCoinBalance = (id) => {

        let cid = id.charAt(0).toUpperCase() + id.slice(1);
        let balance = depositData.filter(item => item.amount )
        let withBalance = withdrawalData.filter(item => item.amount)
        let arrayBalance = balance.map(each => {
          return each.amount
        })
        let withdrawBalance = withBalance.map(each => {
          return each.amount
        })
    
    
        if (arrayBalance.length && withdrawBalance.length > 0) {
          let a =  arrayBalance.reduce((acc, curr)=> Number(acc) + Number(curr))
          let b =  withdrawBalance.reduce((acc, curr)=> Number(acc) + Number(curr))
          let c = (a - b).toFixed(3)
          return cid === "Tether" ? (c + Number(pnl)) : c;
        } else if (arrayBalance.length > 0 && withdrawBalance.length == 0) {
          let d = arrayBalance.reduce((acc, curr)=> Number(acc) + Number(curr))
          return cid === "Tether" ? (d + Number(pnl)) : d;
        } else {
            return cid === "Tether" ? (0.00 + Number(pnl)) : 0.00;
        }
    
    }
    
      const calculateInDollars = (id) => {
        let cid = id.charAt(0).toUpperCase() + id.slice(1)
    
        let arrayFir = coinsList.map(coin => {
          if(coin.id === id) {
            return coin.current_price
          }
        })
    
    
        let multiplierQ = arrayFir.reduce((acc, curr)=> {
          if (acc === undefined) acc = 0
          if (curr === undefined) curr = 0
          return acc + curr
        })
        
        let balance = allBalance.filter(item => item.method === cid)
        let withBalance = allWithdrawal.filter(item => item.method === cid)
        
    
        let arrayBalance = balance.map(each => {
          return each.amount
        })
         
    
        let withdrawBalance = withBalance.map(each => {
          return each.amount
        })
    
        if (arrayBalance.length && withdrawBalance.length > 0) {
          let a =  arrayBalance.reduce((acc, curr)=> Number(acc) + Number(curr))
          let b =  withdrawBalance.reduce((acc, curr)=> Number(acc) + Number(curr))
          let c = ((a - b).toFixed(3) * multiplierQ) + Number(makeFirstSavings())
          return cid === "Tether" ? (c + Number(pnl)) : c;
        } else if (arrayBalance.length > 0 && withdrawBalance.length == 0) {
          let me = arrayBalance.reduce((acc, curr)=> Number(acc) + Number(curr))
          let d = (me * multiplierQ) + Number(makeFirstSavings())
          return cid === "Tether" ? (d + Number(pnl)) : d;
        } else {
        //   return 0.00 + Number(makeFirstSavings()) + pnl
        return cid === "Tether" ? (0.00 + Number(makeFirstSavings()) + Number(pnl)) : (0.00 + Number(makeFirstSavings()));
        }
    }
    
    
      const makeTotalValue = () => {
        const arrayBl = coinsList.map(coin => coin.id)
        let arrayBll = arrayBl.map(coin => {
          
          return calculateInDollars(coin)
        })
        if (arrayBll.length > 0) {
          return arrayBll.reduce((acc, curr)=> Number(acc) + Number(curr))
        } else {
          return 0.00
        }
    
      }

    // const getBalance = (id) => {
    //     let cid = id.charAt(0).toUpperCase() + id.slice(1);
      
    //     let balance = allBalance.filter(item => item.method === cid);
    //     let withBalance = allWithdrawal.filter(item => item.method === cid);
      
    //     let arrayBalance = balance.map(each => Number(each.amount));
    //     let withdrawBalance = withBalance.map(each => Number(each.amount));
      
    //     let netBalance = 0;
      
    //     if (arrayBalance.length && withdrawBalance.length > 0) {
    //       let totalDeposits = arrayBalance.reduce((acc, curr) => acc + curr);
    //       let totalWithdrawals = withdrawBalance.reduce((acc, curr) => acc + curr);
    //       netBalance = totalDeposits - totalWithdrawals;
    //     } else if (arrayBalance.length > 0 && withdrawBalance.length === 0) {
    //       netBalance = arrayBalance.reduce((acc, curr) => acc + curr);
    //     }
      
    //     return (netBalance + pnl).toFixed(3); // Add PnL
    // };

    // const getCoinBalance = () => {
    //     let balance = depositData.filter(item => item.amount);
    //     let withBalance = withdrawalData.filter(item => item.amount);
      
    //     let arrayBalance = balance.map(each => Number(each.amount));
    //     let withdrawBalance = withBalance.map(each => Number(each.amount));
      
    //     let netBalance = 0;
      
    //     if (arrayBalance.length && withdrawBalance.length > 0) {
    //       let totalDeposits = arrayBalance.reduce((acc, curr) => acc + curr);
    //       let totalWithdrawals = withdrawBalance.reduce((acc, curr) => acc + curr);
    //       netBalance = totalDeposits - totalWithdrawals;
    //     } else if (arrayBalance.length > 0 && withdrawBalance.length === 0) {
    //       netBalance = arrayBalance.reduce((acc, curr) => acc + curr);
    //     }
      
    //     return (netBalance + pnl).toFixed(3); // Add PnL
    // };

    // const calculateInDollars = (id) => {
    //     let cid = id.charAt(0).toUpperCase() + id.slice(1);
      
    //     let arrayFir = coinsList.map(coin => {
    //       if (coin.id === id) {
    //         return coin.current_price;
    //       }
    //     });
      
    //     let multiplierQ = arrayFir.reduce((acc, curr) => {
    //       if (acc === undefined) acc = 0;
    //       if (curr === undefined) curr = 0;
    //       return acc + curr;
    //     });
      
    //     let balance = allBalance.filter(item => item.method === cid);
    //     let withBalance = allWithdrawal.filter(item => item.method === cid);
      
    //     let arrayBalance = balance.map(each => Number(each.amount));
    //     let withdrawBalance = withBalance.map(each => Number(each.amount));
      
    //     let netBalance = 0;
      
    //     if (arrayBalance.length && withdrawBalance.length > 0) {
    //       let totalDeposits = arrayBalance.reduce((acc, curr) => acc + curr);
    //       let totalWithdrawals = withdrawBalance.reduce((acc, curr) => acc + curr);
    //       netBalance = totalDeposits - totalWithdrawals;
    //     } else if (arrayBalance.length > 0 && withdrawBalance.length === 0) {
    //       netBalance = arrayBalance.reduce((acc, curr) => acc + curr);
    //     }
      
    //     // Include pnl and savings
    //     return ((netBalance + pnl) * multiplierQ + Number(makeFirstSavings())).toFixed(2);
    // };

    // const makeTotalValue = (pnl) => {
    //     const arrayBl = coinsList.map(coin => coin.id);
    //     let arrayBll = arrayBl.map(coin => calculateInDollars(coin));
      
    //     if (arrayBll.length > 0) {
    //       return arrayBll.reduce((acc, curr) => Number(acc) + Number(curr), 0).toFixed(2);
    //     } else {
    //       return 0.00;
    //     }
    // };




    useEffect(() => {
        fetchCoinsList()
        fetchTrendingCoins()
        fetchCoinData()
      
    }, [])

    useEffect(()=> {
        fetchCoinData() 
    }, [coinState]) 

    return (
        <StateContext.Provider
            value={{activeMenu, setActiveMenu, makeFirstSavings, getBalance, getCoinBalance, makeTotalValue, handleFormChange, isClicked, direction, setDirection, setIsClicked, handleClick, fetchCoinData, coinData, setCoinData, screenSize, setScreenSize, loggedIn, setLoggedIn, newHodler, setNewHodler, fetchCoin, fetchCoinsList, fetchTrendingCoins, coinState, setCoinState, coin, setCoin, graphData, setGraphData, coinsList, setCoinsList, trending, days, setDays, auth, setAuth, userData, setUserData, newSavings, setNewSavings, depositData, setDepositData, withdrawalData, setWithdrawalData, loanData, setLoanData, savingsData, setSavingsData, hodlerData, title, page, setPage, fullUserData, setFullUserData, canSubmit, disableNext, disablePrev, prevHide, nextHide, submitHide, verifyMessage, setVerifyMessage, verifyStatus, setVerifyStatus, allBalance, setAllBalance, allWithdrawal, setAllWithdrawal, savingsInvest, setSavingsInvest, balanceArray, setBalanceArray, pnl, savingsBalance}}
        >
            {children}
        </StateContext.Provider>
    )
}

export const useStateContext = () => useContext(StateContext)
